(function(){
    'use strict';

    angular.module('common')
        .directive('aflUserMenu', aflUserMenu);

    aflUserMenu.$inject = [];

    function aflUserMenu() {
        var directive = {
            restrict: 'E',
            templateUrl: '/static/javascript/directives/afl-user-menu/afl-user-menu.html',
            controller: aflUserMenuController,
            replace: true,
            scope: {
                userFullName: '<',
                email: '<',
                hasCustomerPortalRole: '<',
                hasAdminPortalRole: '<',
                hasMfgPortalRole: '<',
                role: '<',
                roleSpecific: '<'
            },
            transclude: true,
            link: function (scope, element, attributes, controller) {
                scope.LOGIN = controller.LOGIN;
                scope.ADMIN = controller.ADMIN;
                scope.MFG = controller.MFG;
                scope.CUSTOMER = controller.CUSTOMER;

                scope.rolesConstant = controller.rolesConstant;
            }
        };
        return directive;
    }

    aflUserMenuController.$inject = ['routesConstant', 'rolesConstant'];

    function aflUserMenuController(routesConstant, rolesConstant) {
        this.LOGIN = routesConstant.LOGIN;
        this.ADMIN = routesConstant.ADMIN;
        this.MFG = routesConstant.MFG;
        this.CUSTOMER = routesConstant.CUSTOMER;

        this.rolesConstant = rolesConstant;
    }
})();
